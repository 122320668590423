import type { RouteParams } from 'vue-router'
import type { BreadCrumbReturn } from '~/typeEntities/stores/BreadCrumb'
import { getNearestStation, type Clinic } from '~/typeEntities/stores/Clinic'

export const useBreadCrumb = defineStore('index-structures', () => {
  const breadCrumb = ref(new Array<BreadCrumbReturn>())
  const page = ref(1)
  const setBreadCrumb = (
    breadCrumbArg: {
      id: number
      name: string
      slug: string
      titleFormat?: string | null
      h1Format?: string | null
      modelType: string
    }[]
  ) => {
    breadCrumb.value = breadCrumbArg.map((el) => {
      return {
        sourceType: el.modelType,
        sourceName: el.modelType === 'Station' ? `${el.name}駅` : el.name,
        sourceId: el.id,
        sourceSlug: el.slug,
        indexTitle: el.name,
        titleFormat: el.titleFormat,
        h1Format: el.h1Format,
      }
    })
  }
  const flushBreadCrumb = () => {
    breadCrumb.value = new Array<BreadCrumbReturn>()
  }
  const setIndexesByClinic = (clinic: Clinic) => {
    if (!clinic || !clinic.section) {
      return (breadCrumb.value = [])
    }
    const facilityTypeEl = [] as BreadCrumbReturn[]
    if (clinic.facilityTypes.length > 0) {
      const facilityTypes = clinic.facilityTypes
      const facilityType = facilityTypes.reduce((min, current) => {
        return Number(current.priority) < Number(min.priority) ? current : min
      }, facilityTypes[0])
      if (facilityType) {
        facilityTypeEl.push({
          sourceType: 'FacilityType',
          sourceName: facilityType?.name || '',
          sourceId: Number(facilityType?.id || 0),
          sourceSlug: facilityType?.slug,
          indexTitle: facilityType?.name || '',
        })
      }
    }
    const section = clinic.section
    const prefecture = section.prefecture
    const stationEl = [] as BreadCrumbReturn[]
    if (clinic.stations.length > 0) {
      const station = getNearestStation(clinic)
      if (station) {
        stationEl.push({
          sourceType: 'Station',
          sourceName: `${station.name}駅`,
          sourceId: station.id,
          sourceSlug: station.id.toString(),
          indexTitle: station.name,
        })
      }
    }
    const bread = [
      ...facilityTypeEl,
      {
        sourceType: 'Prefecture',
        sourceName: prefecture.name,
        sourceId: Number(prefecture.id),
        sourceSlug: prefecture.slug,
        indexTitle: prefecture.name,
      },
      {
        sourceType: 'Section',
        sourceName: section.name,
        sourceId: Number(section.id),
        sourceSlug: section.id.toString(),
        indexTitle: section.name,
      },
      ...stationEl,
      {
        sourceType: 'Clinic',
        sourceName: clinic.name || '',
        sourceId: Number(clinic.id),
        sourceSlug: '',
        indexTitle: clinic.name || '',
      },
    ]
    breadCrumb.value = bread
  }
  const setIndexesByClinicArticle = (
    clinic: Clinic,
    article: {
      id: number
      title: string | null
    }
  ) => {
    setIndexesByClinic(clinic)
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'Article',
        sourceName: article.title || '',
        sourceId: article.id,
        sourceSlug: '',
        indexTitle: article.title || '',
      },
    ]
  }
  const setIndexesByClinicReport = (
    clinic: Clinic,
    report: {
      id: number
      user: {
        nickname: string | null
      } | null
    }
  ) => {
    setIndexesByClinic(clinic)
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'Review',
        sourceName: `${report.user?.nickname}の口コミ・評判` || '',
        sourceId: report.id,
        sourceSlug: '',
        indexTitle: `${report.user?.nickname}の口コミ・評判` || '',
      },
    ]
  }
  const setIndexesByClinicReports = (clinic: Clinic) => {
    setIndexesByClinic(clinic)
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'Review',
        sourceName: `${clinic.name || ''}の口コミ一覧`,
        sourceId: 0,
        sourceSlug: '',
        indexTitle: `${clinic.name || ''}の口コミ一覧`,
      },
    ]
  }
  const setIndexesByClinicDoctors = (clinic: Clinic) => {
    setIndexesByClinic(clinic)
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'Doctor',
        sourceName: `${clinic.name || ''}の医師・ドクター一覧`,
        sourceId: 0,
        sourceSlug: '',
        indexTitle: `${clinic.name || ''}の医師・ドクター一覧`,
      },
    ]
  }
  const setIndexesByClinicMenus = (clinic: Clinic) => {
    setIndexesByClinic(clinic)
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'Menu',
        sourceName: `${clinic.name || ''}の施術メニュー・料金一覧`,
        sourceId: 0,
        sourceSlug: '',
        indexTitle: `${clinic.name || ''}の施術メニュー・料金一覧`,
      },
    ]
  }
  const setIndexesByClinicMedicalCase = (
    clinic: Clinic,
    medicalCase: {
      id: number
      title: string | null
    }
  ) => {
    setIndexesByClinic(clinic)
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'MedicalCase',
        sourceName: medicalCase.title || '',
        sourceId: medicalCase.id,
        sourceSlug: '',
        indexTitle: medicalCase.title || '',
      },
    ]
  }
  const setIndexesByDoctor = (
    clinic: Clinic | null,
    doctor: {
      id: number
      name: string | null
    }
  ) => {
    if (clinic) {
      setIndexesByClinic(clinic)
    } else {
      flushBreadCrumb()
    }
    breadCrumb.value = [
      ...breadCrumb.value,
      {
        sourceType: 'Doctor',
        sourceName: doctor.name || '',
        sourceId: doctor.id,
        sourceSlug: '',
        indexTitle: doctor.name || '',
      },
    ]
  }
  const setPage = (pageVal: number) => {
    page.value = pageVal
  }
  const fetchBreadCrumbByRouteParams = async (routeParams: RouteParams) => {
    const sanitizeParams = (str: string | string[] | undefined) => {
      if (str === undefined || Array.isArray(str)) return ''

      return str
    }
    const facilityTypeSlug = sanitizeParams(routeParams.fSlug)
    const treatmentCategorySlug = sanitizeParams(routeParams.tcSlug)
    const treatmentSlug = sanitizeParams(routeParams.tSlug)
    const listSubjectSlug = sanitizeParams(routeParams.lSlug)
    const prefectureSlug = sanitizeParams(routeParams.pSlug)
    const sectionSlug = sanitizeParams(routeParams.sSlug)
    const stationSlug = sanitizeParams(routeParams.stSlug)
    const { data } = await useFetch('/api/v1/breadcrumb', {
      params: {
        facilityTypeSlug,
        treatmentCategorySlug,
        treatmentSlug,
        listSubjectSlug,
        prefectureSlug,
        sectionSlug,
        stationSlug,
      },
    })
    return data.value
  }
  const getBreadCrumbsToShow = computed(() => {
    const bc = breadCrumb.value.filter(
      (b) => b.sourceType !== 'TreatmentCategory'
    )
    if (page.value === 1) {
      return bc
    }
    bc.push({
      sourceType: 'Page',
      sourceName: `${page.value}ページ目`,
      sourceId: page.value,
      sourceSlug: '',
      indexTitle: `${page.value}ページ目`,
    })
    return bc
  })
  const getElementBySourceType = (sType: string) => {
    return breadCrumb.value.find((el) => {
      return el.sourceType === sType
    })
  }

  const getIdsBySourceType = (sType: string) => {
    return breadCrumb.value
      .filter((el) => el.sourceType === sType)
      .map((el) => Number(el.sourceId))
  }
  const buildPathBybreadArray = (array: BreadCrumbReturn[]) => {
    const breadArray = array
    if (!breadArray) {
      return ''
    }
    let pathArray = breadArray.map((el) => {
      switch (el.sourceType) {
        case 'FacilityType':
          if (breadArray.some((bread) => bread.sourceType === 'Treatment'))
            return ''
          if (el.sourceId === 1 && breadArray.length !== 1) return ''

          return `f-${el.sourceSlug}`
        case 'TreatmentCategory':
          return `tc-${el.sourceSlug}`
        case 'Treatment':
          return `t-${el.sourceSlug}`
        case 'ListSubject':
          return `l-${el.sourceId}`
        case 'Prefecture':
          return `p-${el.sourceSlug}`
        case 'Section':
          return `s-${el.sourceId}`
        case 'Station':
          return `st-${el.sourceId}`
        case 'QuestionKeyword':
          return `question-keywords/${el.sourceId}`
        case 'Question':
          return `questions/${el.sourceId}`
        case 'Word':
          return `reports/w-${el.sourceId}`
        case 'Clinic':
          return ''
        case 'Article':
          return ''
        case 'Review':
          return ''
        case 'Doctor':
          return ''
        case 'Search':
          return ''
        case 'MedicalCase':
          return ''
        case 'Media':
          return ''
        case 'Menu':
          return ''
        default:
          throw new Error(
            `${el.sourceType} is not valid source type for build patharray.`
          )
      }
    })
    pathArray = pathArray.filter((el) => el !== '')
    return '/' + pathArray.join('/')
  }
  const getPathOf = (element: BreadCrumbReturn) => {
    let breadArray = breadCrumb.value
    if (!breadArray) {
      return ''
    }
    if (element.sourceType === 'Clinic') {
      return `/clinics/${element.sourceId}`
    }
    if (element.sourceType === 'Review') {
      const clinicId = getElementBySourceType('Clinic')?.sourceId
      const tailParam = element.sourceId ? `/${element.sourceId}` : ''
      return `/clinics/${clinicId}/reports${tailParam}`
    }
    if (element.sourceType === 'Doctor') {
      return `/doctors/${element.sourceId}`
    }
    if (element.sourceType === 'MedicalCase') {
      const clinicId = getElementBySourceType('Clinic')?.sourceId
      return `/clinics/${clinicId}/medical_cases/${element.sourceId}`
    }
    if (element.sourceType === 'Media') {
      return '/media'
    }
    if (element.sourceType === 'Menu') {
      const clinicId = getElementBySourceType('Clinic')?.sourceId
      return `/clinics/${clinicId}/menus`
    }

    const elementIndex = breadArray.findIndex(
      (el) =>
        el.sourceId === element.sourceId && el.sourceType === element.sourceType
    )
    if (elementIndex < 0) {
      return ''
    }
    breadArray = breadArray.slice(0, elementIndex + 1)
    return buildPathBybreadArray(breadArray)
  }
  const getFacilityTypeBreads = computed(() => {
    return breadCrumb.value.filter((el) => el.sourceType === 'FacilityType')
  })
  const getTreatmentBreads = computed(() => {
    return breadCrumb.value.filter(
      (el) =>
        el.sourceType === 'Treatment' || el.sourceType === 'TreatmentCategory'
    )
  })
  const getAreaBreads = computed(() => {
    return breadCrumb.value.filter(
      (el) =>
        el.sourceType === 'Prefecture' ||
        el.sourceType === 'Section' ||
        el.sourceType === 'Station'
    )
  })
  const getBottomArea = computed(() => {
    return (
      breadCrumb.value.find((el) => el.sourceType === 'Station') ||
      breadCrumb.value.find((el) => el.sourceType === 'Section') ||
      breadCrumb.value.find((el) => el.sourceType === 'Prefecture')
    )
  })
  const getCanonicalPath = () => {
    const topBreadCrumb = breadCrumb.value
    if (!topBreadCrumb) {
      return ''
    }
    const element = topBreadCrumb.at(-1)
    if (!element) {
      return ''
    }

    return getPathOf(element)
  }
  const getMetaTitle = computed(() => {
    const listSubject = getElementBySourceType('ListSubject')
    if (listSubject && listSubject.h1Format) return getFormatMetaTitle.value

    let pageText = 'TOP20'
    if (page.value > 1) {
      pageText = ` - ${page.value}ページ目`
    }
    return `${getMetaTitlePrefix.value}おすすめ${getTitleClinic.value}${pageText}`
  })
  const getMetaDescription = computed(() => {
    return `${getDescriptionPrefix.value}人気のおすすめ${getTitleClinic.value}をご紹介。口コミレビューや料金の比較で自分に合った特徴のクリニックが見つかります。モニター施術メニューも多数ご紹介。`
  })
  const getFormatMetaTitle = computed(() => {
    const listSubject = getElementBySourceType('ListSubject')
    if (listSubject && listSubject.titleFormat) {
      return replaceFormatString(
        listSubject.titleFormat,
        listSubject.sourceName
      )
    }
    return getDefaultTitle.value
  })
  const getDefaultTitle = computed(() => {
    let pageText = 'TOP20'
    if (page.value > 1) {
      pageText = ` - ${page.value}ページ目`
    }
    return `${getTitlePrefix.value}人気のおすすめ${getTitleClinic.value}${pageText}`
  })
  const getH1 = computed(() => {
    const listSubject = getElementBySourceType('ListSubject')
    if (listSubject && listSubject.h1Format) {
      return replaceFormatString(listSubject.h1Format, listSubject.sourceName)
    }
    return getDefaultTitle.value
  })
  const replaceFormatString = (format: string, nameString: string) => {
    // listSubject.titleFormatに基づいてタイトルを生成する
    // hasArea.valueがtrueの場合は、[area]と[/area]に囲まれた部分の中の{areaString}をareaString.valueに置換して[area]と[/area]のタグを削除する
    // hasArea.valueがfalseの場合は、[area]と[/area]に囲まれた部分を全て削除する
    // {name}はlistSubject.sourceNameに置換する
    return format
      .replace(/\[area\](.*?)\[\/area\]/, (_, p1) => {
        if (hasArea.value) {
          return p1.replace(/{areaString}/, areaString.value)
        }
        return ''
      })
      .replace(/\{name\}/, nameString)
  }
  const areaString = computed(() => {
    return (
      getElementBySourceType('Station')?.sourceName ||
      getElementBySourceType('Section')?.sourceName ||
      getElementBySourceType('Prefecture')?.sourceName
    )
  })
  const hasArea = computed(() => {
    return !!(
      getElementBySourceType('Station') ||
      getElementBySourceType('Section') ||
      getElementBySourceType('Prefecture')
    )
  })
  const treatmentString = computed(() => {
    return getElementBySourceType('Treatment')?.sourceName
  })

  const listSubjectString = computed(() => {
    return getElementBySourceType('ListSubject')?.sourceName
  })

  const getMetaTitlePrefix = computed(() => {
    const filteredArr: (string | undefined)[] = []

    if (areaString.value !== undefined) {
      filteredArr.push(`【${areaString.value}】`)
    }
    if (treatmentString.value !== undefined) {
      filteredArr.push(`${treatmentString.value}ができる`)
    }
    if (listSubjectString.value !== undefined) {
      filteredArr.push(`「${listSubjectString.value}」の悩みを解決できる`)
    }
    return filteredArr.join('')
  })
  const getTitlePrefix = computed(() => {
    const filteredArr: (string | undefined)[] = []

    if (areaString.value !== undefined) {
      filteredArr.push(`${areaString.value}で`)
    }

    if (treatmentString.value !== undefined) {
      filteredArr.push(`${treatmentString.value}ができる`)
    }

    if (listSubjectString.value !== undefined) {
      filteredArr.push(`${listSubjectString.value}を解決できる`)
    }

    return filteredArr.join('')
  })

  const getDescriptionPrefix = computed(() => {
    const area =
      getElementBySourceType('Station')?.sourceName ||
      getElementBySourceType('Section')?.sourceName ||
      getElementBySourceType('Prefecture')?.sourceName
    const treatment = getElementBySourceType('Treatment')?.sourceName
    const filteredArr: (string | undefined)[] = []

    if (area !== undefined) {
      filteredArr.push(`${area}で`)
    }
    if (treatment !== undefined) {
      filteredArr.push(`${treatment}ができる`)
    }
    return filteredArr.join('')
  })

  const getTitleClinic = computed(() => {
    return (
      getElementBySourceType('FacilityType')?.sourceName || '美容クリニック'
    )
  })
  const getSearchOptionObjects = computed(() => {
    const treatmentCategory = getElementBySourceType('TreatmentCategory')
    const treatment = getElementBySourceType('Treatment')
    const facilityType = (() => {
      if (treatment) {
        return undefined
      } else {
        return getElementBySourceType('FacilityType')
      }
    })()
    const listSubject = getElementBySourceType('ListSubject')
    const prefecture = getElementBySourceType('Prefecture')
    const section = getElementBySourceType('Section')
    const station = getElementBySourceType('Station')
    return {
      treatmentCategory,
      treatment,
      listSubject,
      facilityType,
      prefecture,
      section,
      station,
    }
  })
  const isListPage = computed(() => {
    if (breadCrumb.value.length === 0) return false

    const lastElement = breadCrumb.value[breadCrumb.value.length - 1]
    return (
      lastElement.sourceType === 'FacilityType' ||
      lastElement.sourceType === 'TreatmentCategory' ||
      lastElement.sourceType === 'Treatment' ||
      lastElement.sourceType === 'Prefecture' ||
      lastElement.sourceType === 'Section' ||
      lastElement.sourceType === 'Station'
    )
  })
  return {
    breadCrumb,
    page,
    setBreadCrumb,
    flushBreadCrumb,
    setIndexesByClinic,
    setIndexesByClinicArticle,
    setIndexesByClinicReport,
    setIndexesByClinicReports,
    setIndexesByClinicDoctors,
    setIndexesByClinicMenus,
    setIndexesByClinicMedicalCase,
    setIndexesByDoctor,
    setPage,
    fetchBreadCrumbByRouteParams,
    buildPathBybreadArray,
    isListPage,
    getFacilityTypeBreads,
    getAreaBreads,
    getBottomArea,
    getTreatmentBreads,
    getBreadCrumbsToShow,
    getElementBySourceType,
    getIdsBySourceType,
    getPathOf,
    getCanonicalPath,
    getSearchOptionObjects,
    getMetaTitle,
    getMetaDescription,
    getH1,
  }
})
